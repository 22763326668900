import React from "react";
import PropTypes from "prop-types";
import styles from "./productlist.module.scss";
import { money } from "../../services/formatters";
import ListView from "./list/ListView";
import translated from "../../services/i18n/translated";
import {
  UNKNOWN_PRODUCT,
  IN_PROMOTION,
  SEE_PROMOTION,
} from "../../services/i18n/messages";
import iconInfo from "../../assets/info.svg";
import { useIsMedium, useIsLarge } from "../hooks";

const ProductList = ({
  locale,
  title,
  products,
  onProductClick,
  translate,
}) => {
  const isMedium = useIsMedium();
  const isLarge = useIsLarge();

  const moneyFormatter = money(locale);
  return (
    <>
      {isMedium && title && <div className={styles.title}>{title}</div>}
      <ListView title={!isMedium ? title : null} responsive>
        {products.map((product, idx) => (
          <button
            type="button"
            className={styles.product}
            key={product.id}
            onClick={() => onProductClick(product)}
          >
            {product.picture && (
              <div
                role="button"
                className={styles.icon}
                onClick={() => onProductClick(product, "picture")}
                onKeyPress={() => {}}
                tabIndex={idx}
              >
                <img alt="" src={product.picture} />
              </div>
            )}
            {isLarge && (
              <div className={styles.body}>
                <div>
                  <div className={styles.productName}>
                    {`${
                      product.name ? product.name : translate(UNKNOWN_PRODUCT)
                    }`}
                    {product.initialPrice &&
                      product.initialPrice > product.price && (
                        <div className={styles.promotion}>
                          {translate(IN_PROMOTION, {
                            smart_count: product.promotionTags.length,
                          })}
                        </div>
                      )}
                  </div>
                  <div className={styles.productPrice}>
                    {moneyFormatter.format(product.price)}
                    {product.initialPrice &&
                      product.initialPrice > product.price && (
                        <del className={styles.productPriceInitial}>
                          {moneyFormatter.format(product.initialPrice)}
                        </del>
                      )}
                  </div>
                </div>
                <div className={styles.quantityContainer}>
                  <span className={styles.quantity}>
                    {`x${product.quantity}`}
                  </span>
                  {product.initialPrice &&
                    product.initialPrice > product.price && (
                      <div
                        className={styles.seePromotion}
                        onClick={() => onProductClick(product, "info")}
                        role="button"
                        onKeyPress={() => {}}
                        tabIndex={idx}
                      >
                        {translate(SEE_PROMOTION)}
                      </div>
                    )}
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  className={styles.infoIcon}
                  src={iconInfo}
                  alt="info"
                  onClick={() => onProductClick(product, "info")}
                  onKeyPress={() => {}}
                  tabIndex={idx}
                />
              </div>
            )}
            {!isLarge && (
              <>
                <span className={styles.productName}>
                  {`${
                    product.name ? product.name : translate(UNKNOWN_PRODUCT)
                  } (x${product.quantity})`}
                </span>
                <span className={styles.productPrice}>
                  {moneyFormatter.format(product.price)}
                </span>
              </>
            )}
          </button>
        ))}
      </ListView>
    </>
  );
};

ProductList.defaultProps = {
  onProductClick: () => {},
  title: undefined,
};

ProductList.propTypes = {
  locale: PropTypes.string.isRequired,
  title: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      quantity: PropTypes.number.isRequired,
      name: PropTypes.string,
      price: PropTypes.number.isRequired,
      initialPrice: PropTypes.number,
      tax: PropTypes.number.isRequired,
    })
  ).isRequired,
  onProductClick: PropTypes.func,
  translate: PropTypes.func.isRequired,
};

export default translated(ProductList);
